html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: black;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* @font-face {
  font-family: "GothamLight";
  src: local("GothamLight"),
    url(../assets/fonts/Gotham-Light.ttf) format("truetype");
}
@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"),
    url(../assets/fonts/Gotham-Book.ttf) format("truetype");
}
@font-face {
  font-family: "GothamBold";
  src: local("GothamBold"),
    url(../assets/fonts/GOTHAM-BOLD.ttf) format("truetype");
}
@font-face {
  font-family: "GothamMedium";
  src: local("GothamMedium"),
    url(../assets/fonts/Gotham-Medium.ttf) format("truetype");
} */
@font-face {
  font-family: "GothamLight";
  src: local("GothamLight"),
    url(../assets/fonts/TypeFezStd-Light.ttf) format("truetype");
}
@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"),
    url(../assets/fonts/TypeFezStd-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "GothamBold";
  src: local("GothamBold"),
    url(../assets/fonts/TypeFezStd-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "GothamMedium";
  src: local("GothamMedium"),
    url(../assets/fonts/TypeFezStd-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "HalenoirExpandedBlack";
  src: local("HalenoirExpandedBlack"),
    url(../assets/fonts/HalenoirExpanded-Black.otf) format("opentype");
}
@font-face {
  font-family: "TypeFezNeueBold";
  src: local("TypeFezNeueBold"),
    url(../assets/fonts/TypeFezNeue-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "TypeFezNeueRegular";
  src: local("TypeFezNeueRegular"),
    url(../assets/fonts/TypeFezNeue-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "TypeFezNeueMedium";
  src: local("TypeFezNeueMedium"),
    url(../assets/fonts/TypeFezNeue-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "TypeFezNeueLight";
  src: local("TypeFezNeueLight"),
    url(../assets/fonts/TypeFezNeue-Light.ttf) format("truetype");
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}

:root {
  touch-action: pan-x pan-y;
  height: 100%;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
