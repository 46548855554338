.searchBatch {
  width: 476px;
  padding: 8px 8px 8px 36px;
  font-size: 14px;
  font-family: GothamBook;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  color: #727272;
}

.searchBatch:active,
.searchBatch:focus {
  outline: none;
}

.hideScrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.hideScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*hide increase decrease type number*/
/*Chrome, Safari, Edge, Opera*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
