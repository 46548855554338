.modal-transfez {
  border-radius: 12px;
  width: auto;
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%);
}

.modal-border-radius {
  border-radius: 20px !important;
  overflow: hidden !important;
  border: none !important;
}

.modal-enhancement-center {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.modal-add-recipient {
  border-radius: 12px;
  width: auto;
}
.right-card-modal {
  border: 0px solid transparent;
  border-radius: 24px;
}

.modal-close-button {
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.settings {
  box-shadow: 0px 4px 8px rgba(88, 88, 88, 0.06);
}

.hidescrollbar::webkit-scrollbar {
  display: none;
}
.hidescrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
