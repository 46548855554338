.error-toaster {
  background-color: #e95757;
  border-radius: 12px;
  box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
}
.success-toaster {
  background-color: #28ab7c;
  border-radius: 12px;
  box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
}

.warning-toaster {
  background-color: #e1b456;
  border-radius: 12px;
  box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
}
