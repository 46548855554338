.white-arrow[x-placement^="top"] .arrow::before {
  border-top-color: white !important;
}

.white-arrow[x-placement^="bottom"] .arrow::before {
  border-bottom-color: white !important;
}

.white-arrow[x-placement^="left"] .arrow::before {
  border-left-color: white !important;
}

.white-arrow[x-placement^="right"] .arrow::before {
  border-right-color: white !important;
}
