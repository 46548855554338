.bs-tooltip-autox[x-placement^="top"] .arrow::before {
  border-top-color: aqua !important;
}
.btn {
  background-color: #da649f;
  border-radius: 24px;
  color: #fcfcfc;
  font-size: 16px;
  line-height: 24px;
  /* box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12); */
  transition: box-shadow 0.3s ease;
  transition: background-color 0.3s ease;
  height: 48px;
  font-family: GothamMedium;
  box-shadow: none;
}

.btn:hover {
  /* box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.12); */
  color: #fcfcfc;
  background-color: #d2468c;
}

.btn:focus {
  box-shadow: none;
}

.btn-pink {
  transition: background-color 0.3s ease;
  background-color: transparent;
  border: 2px solid #da649f;
}

.btn-pink:hover {
  background-color: #fdd7f5;
}

.btn-blue {
  background-color: #e5f9fc;
}

.btn-blue:hover {
  background-color: #49abb8;
}
.btn-dark-blue {
  transition: background-color 0.3s ease;
  background-color: #49abb8;
}

.btn-dark-blue:hover {
  background-color: #218391;
  cursor: pointer;
}
.btn-fileinput {
  transition: background-color 0.3s ease;
  background-color: transparent;
  border-radius: 20px;
  border-width: 0;
  color: #218390;
  font-size: 20px;
  height: 40px;
  font-family: GothamMedium;
}

.btn-fileinput:hover {
  color: #218390;
  background-color: #fdd7f5;
}

.greybtn {
  background-color: #fff;
  border-radius: 12px;
  color: #252525;
  border: 1px solid #252525;
  font-family: "GothamBook";
  font-size: 20px;
  line-height: 28px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.3s ease;
  font-family: GothamBook;
  font-weight: bold;
}

.greybtn:hover {
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.12);
  background-color: #c2c2c2;
}
.outline {
  background-color: transparent;
  border-width: 0;
  height: 48px;
  border-radius: 24px;
  color: white;
  border: 2px solid white;
  font-family: "GothamBook";
  font-size: 16px;
  /* line-height: 24px; */
  transition: box-shadow 0.3s ease;
  transition: background-color 0.3s ease;
  font-family: GothamBook;
  font-weight: bold;
}

.outline:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.link {
  color: #da649f;
  text-decoration: underline;
}
.link:hover {
  cursor: pointer;
  color: #aa4879;
}

.link-table {
  color: #da649f;
  text-decoration: underline;
  font-size: 16px;
  font-family: "GothamBook";
}

.sidebar {
  background-color: transparent;
  transition: background-color 0.3s ease;
}
.sidebar:hover {
  cursor: pointer;
  background-color: rgba(229, 249, 252, 0.2);
}
.hover:hover {
  cursor: pointer;
}
.no-hover:hover {
  cursor: default;
}
.darkhover {
  background-color: transparent;
  transition: background-color 0.3s ease;
  border-radius: 8px;
}
.darkhover:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.12);
}
.lighthover {
  background-color: transparent;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}
.lighthover:hover {
  cursor: pointer;
  background-color: #f8f8f8;
}
.pinkhover {
  background-color: transparent;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}
.pinkhover:hover {
  cursor: pointer;
  background-color: #fdd7f5;
}

.pinkhover-border {
  background-color: transparent;
  transition: background-color 0.3s ease;
  border-radius: 4px;
  border: 1px solid #e6e6e8;
}
.pinkhover-border:hover {
  cursor: pointer;
  background-color: #fdd7f5;
  border: 1px solid #fdd7f5;
}

.hover-pink {
  background-color: transparent;
  transition: all 0.3s ease;
}
.hover-pink:hover {
  background-color: #fdd7f5;
}

.option {
  background-color: transparent;
  color: "white";
  transition: background-color 0.3s ease;
}

.option:hover {
  cursor: pointer;
  background-color: #fdd7f5;
}

.filterbtn {
  background-color: #c1eff4;
  border-radius: 12px;
  color: #218390;
  border: transparent;
  font-size: 20px;
  line-height: 28px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
  padding-top: 10px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 10px;
  transition: 0.5s;
  transition: box-shadow 0.5s ease;
  font-family: GothamMedium;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterbtn:hover {
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.12);
  background-color: #49abb8;
}

.dropdownBatch {
  padding: 24px 0px 24px 0px;
}

.dropdownMenu {
  width: 100%;
  background-color: white;
  border-radius: 12px;
  font-family: GothamBook;
  color: #727272;
}

.dropdownItem {
  color: #727272;
}

.dropdownItem:hover {
  color: #727272;
  background-color: #c1eff4;
}

.toggleBatch {
  width: 100%;
  height: auto;
  background-color: white;
  padding: 16px 16px 16px 12px;
  border: 1px solid #c2c2c2 !important;
  font-family: GothamBook;
  text-align: left;
  font-size: 16px;
  color: #727272;
}

.toggleBatch:after {
  margin-top: 8px;
  float: right;
  color: #727272;
}

.toggleBatch:hover,
.toggleBatch:active,
.toggleBatch:focus,
.toggleBatch:visited {
  background-color: white !important;
  border-color: #c2c2c2 !important;
  color: #727272 !important;
  box-shadow: none !important;
}

.show > .btn-primary.dropdown-toggle {
  background-color: white;
  color: #727272 !important;
}

.paginationBttns {
  width: 100%;
  height: 40px;
  padding-left: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  font-family: GothamMedium;
  margin-bottom: 0;
  color: #727272;
  font-size: 16px;
}
.fake-button {
  color: #eb5b42;
  border-width: 0;
  background-color: transparent;
  font-size: 14px;
  font-family: GothamBook;
}
.paginationBttns a {
  justify-content: center;
  padding: 10px;
  margin: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.paginationActive {
  color: white;
  outline: none;
}

.paginationActive a {
  background-color: #49abb8;
  cursor: pointer;
  border-radius: 4px;
}

.paginationActive a:focus {
  outline: none;
}

.paginationBttns a:focus {
  outline: none;
}

.selection-pink::selection {
  color: #102c57;
  background-color: #fdd7f5;
}
