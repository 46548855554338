.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-btn {
  display: flex;
  justify-content: flex-end;
}

.top-font {
  font-size: 20px;
  line-height: 24px;
  color: #102c57;
  letter-spacing: 1px;
}

.middle-font {
  font-size: 14px;
  line-height: 20px;
  color: #333334;
  letter-spacing: 0.1px;
  text-align: center;
  margin-top: 16px;
}

.plan-container {
  display: flex;
  gap: 24px;
}

.plan {
  width: 400px;
  height: 485px;
  border: 1px solid black;
  border-radius: 12px;
  padding: 24px;
  position: relative;
  overflow: hidden;
}

.button {
  height: 40px;
  width: 100%;
  background-color: #da649f;
  border-radius: 20px;
  border: none;
  margin-top: 16px;
}

.button-font {
  color: white;
  font-size: 16px;
  line-height: 24px;
}

.terms {
  display: inline;
  font-size: 12px;
  line-height: 16px;
  color: #6c6c71;
}

.terms-link {
  display: inline;
  font-size: 12px;
  line-height: 16px;
  color: #da649f;
  cursor: pointer;
}
