.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-btn {
  display: flex;
  justify-content: flex-end;
}

.top-font {
  font-size: 20px;
  line-height: 24px;
  color: #102c57;
  letter-spacing: 1px;
}

.middle-font {
  font-size: 14px;
  line-height: 20px;
  color: #333334;
  letter-spacing: 0.1px;
  text-align: center;
  margin-top: 16px;
}

.plan-container {
  display: flex;
  gap: 24px;
}

.plan {
  width: 400px;
  height: 485px;
  border: 1px solid black;
  border-radius: 12px;
  padding: 24px;
  position: relative;
  overflow: hidden;
}

.button {
  height: 40px;
  width: 100%;
  background-color: #da649f;
  border-radius: 20px;
  border: none;
  margin-top: 16px;
}

.button-2 {
  height: 40px;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #e6e6e8;
  margin-top: 8px;
  transition: all 0.2s;
}

.button-2:hover {
  background-color: #da649f0b;
}

.button-text {
  color: white;
}

.button-text-2 {
  color: #da649f;
}
