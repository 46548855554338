.font8 {
  font-size: 8px;
  line-height: 14px;
}

.font10 {
  font-size: 10px;
  line-height: 16px;
}

.font12 {
  font-size: 12px;
  line-height: 18px;
}
.font14 {
  font-size: 14px;
  line-height: 22px;
}

.font16 {
  font-size: 16px;
  line-height: 24px;
}

.font20 {
  font-size: 20px;
  line-height: 28px;
}

.font24 {
  font-size: 24px;
  line-height: 32px;
}

.wo-underline {
}
.wo-underline:hover {
  text-decoration: none;
}
