.icon-spin {
  animation: icon-spin 1.4s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.scrollable-nav-y {
  overflow-y: scroll;
}

.scrollable-nav-y::-webkit-scrollbar:vertical {
  width: 0px;
}

.scrollable-nav-y::-webkit-scrollbar-thumb {
  border-radius: 8px;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.15);
}

.scrollable-nav-y::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scrollable-nav {
  display: block;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  height: auto;
}

.scrollable-nav .navbar-item {
  display: inline-block;
}

.scrollable-nav::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scrollable-nav::-webkit-scrollbar-thumb {
  border-radius: 4px;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.15);
}

.scrollable-nav::-webkit-scrollbar {
  -webkit-appearance: none;
}
